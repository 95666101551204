
.insurance_banner-image {
    scale: 0.60;
    position: absolute;  
    left:30%;
    clip-path: circle(50%);
    /* clip-path: circle(50%); */
    /* position: absolute; */
    /* left:57% */
    /* left:50%; */
}