.about-us-right-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-items: center;
}

.about-us-right-container img {
    width: 35%;
    height: auto;
    position: relative;
    clip-path: inset(0 8% 0 8%);
}
        
.about-us-upper {
    /* position: absolute; */
    /* top: 10%;  */
    /* position: relative; */
    /* left :25%; */
    /* width:100px; */
    /* height: auto;  */
}

.about-us-lower {
    /* height: auto; */
    /* position: absolute; */
    /* top: 20%; */
    /* left :17%; */
}

@media (max-width: 768px) {
    .about-us-right-container {
        flex-direction: column; /* 在移动端上调整为垂直布局 */
        align-items: center; /* 在移动端上居中对齐 */
    }

    .about-us-right-container img {
        width: 80%; /* 图片宽度调整为 80% */
        clip-path: none; /* 移除剪切路径 */
    }

    .about-us-upper,
    .about-us-lower {
        display: None;
    }
}