
.about-us-container {
    font-size: 20px;
    color: #FE5D27;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding:40px;
    line-height: 30px;
}
.about-us-title {
    font-size:40px;
    color:#FE5D27;
}


.about-us-learn-more-btn {
    background-color: #FE5D27;
    color: white;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    width: 140px;
}

/* .about-us-description{
    color: black;
    margin-bottom: 80px;
    line-height: 26px;
} */