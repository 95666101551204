
.space-grotesk-spaces {
  font-family: "Space Grotesk", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

.navigation-bar {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    /* justify-content: center; */
    height: 60px;
    padding:20px;
    position: sticky;
    top:0;
    z-index: 1000;
    background-color: white;
    /* background-color: red; */
}

/* .tabs {  */
    /* display: flex;  */
    /* justify-content: space-between; */
    /* align-items: center; */
    /* margin-left: 20px; */
    /* gap: 30px; */
    /* font-size: 20px; */
    /* font-family: 'Space Grotesk';  */
/* } */

.tab{
    cursor: pointer;
    font-weight: 50;
    font-family: 'Space Grotesk';
    color: rgba(75, 68, 68, 0.923);
    font-family :'space-grotesk-spaces';
    text-decoration: none;
    padding:0px 10px;
    display: block;
    white-space: nowrap;
    line-height: 40px;
    /* flex-wrap: nowrap; */
    /* min-width: 200px; */
}

.selected {
    font-family: 'Space Grotesk';
    background-color: #FE5D27;
    color:white;
    padding:0px 10px;
    height:40px;
    line-height: 40px;
    text-align: center;
    border-radius: 6px;
    font-family: inherit;
    text-decoration: none;
    font-family :'space-grotesk-spaces';
}

/* .tab:hover {
    position: relative;
    padding-bottom: 10px;
    top:8px
} */

/* .tab:hover::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #FE5D27; 
    margin-top: 8px; 
}
 */

@media (max-width: 768px) {
    .navigation-bar {
        justify-content: center; /* 在移动端上居中显示 */
    }

    .tabs {
        /* display: none; 在移动端上隐藏 .tabs */
        display:grid;
        grid-template-columns: 10px 10px 10px 10px;
    }

    .tab {
        font-size: 18px; /* 调整字体大小以适应移动端 */
    }

    .tab:hover {
        top: 2px; /* 调整 hover 效果的位置 */
    }

    .tab:hover::after {
        margin-top: 6px; /* 调整下划线的位置 */
    }
}

