.feature-item {
    margin: 10px;
    background-color: white; /* 设置白色背景 */
    overflow: hidden; /* 隐藏超出部分 */
    position: relative; /* 确保相对定位 */
  }
  
  .highlight {
    background: linear-gradient(to right, white 20%, #FE5D27); /* 渐变背景色 */
    animation: backgroundFlash 2s ease-in-out infinite; /* 添加背景闪过动画 */
  }
  
  @keyframes backgroundFlash {
    0%, 100% {
      background-position: -200% 0; /* 起始和结束位置，向左移动200% */
    }
    50% {
      background-position: 100% 0; /* 中间位置，向右移动100% */
    }
  }
  