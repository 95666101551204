/* when sm add style  */

/* .info-card{
    height:510px;
    background-color: #E3E7DD;
    min-width:300px;
    max-width: 410px;
    overflow: hidden;
    height: 610px;
    border-radius: 5px;
    position: relative;
    line-height: 23px;
    font-size: 18px;
}
 */

.info-card__image{
    width:100%;
    height: 70%;
    overflow: hidden;
}

/* .info-card__title{
    padding: 10px 0 0px 0px;
    display: block;
    text-align: center;
    font-weight: 900;
    font-size: 25px;
} */

.info-card-btn-container{
    display:flex;
    justify-content: space-evenly;
    margin-top: 20px;
    flex:1 1 auto;
    position: absolute;
    top:86%;
    left:29.5%;
}

.info-card__description{
    padding: 0px 20px 20px 30px;
}

.learn-more-btn{
    background-color: #FE5D27;
    color: white;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}
