:root {
    --banner-content-left:8%;
}

.banner-image {
    scale: 0.90;
    clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 25% 100%, 0% 52%, 25% 0%);
    position: absolute;
    top:-40%;
    left:57%;
    transform: translate(-290px,-300px);
}

.main_comp1 {
    position: absolute;
    left:var(--banner-content-left);
    top:20%;
    width:700px;
}
/* address */
.main_comp2 {
    position: absolute;
    left:var(--banner-content-left);
    top:80%;
    font-size: 30px;
}
.main_comp3 {
    position: absolute;
    left:var(--banner-content-left);
    font-size: 22px;
    line-height: 45px;
    text-align: center;
    top:57%;
    border-radius: 10px;
}