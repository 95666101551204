@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.space-grotesk-spaces {
  font-family: "Space Grotesk", sans-serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  /* font-style: normal; */
}

body {
  margin: 0;
  font-family:  'space-grotesk-spaces',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
