.about-us {
    display: flex;
    height:600px;
    background-color: #D9D9D9;
    /* background-color: #FE5D27; */
    justify-items: space-between;
    flex-direction: row;
    position: relative;
    margin: 0px 15px;
}

.about-us-left {
    flex:1;
    margin:auto;
    /* background-color: blue; */
}
.about-us-right {
    position: relative;
    flex:1;
    /* left:50%; */
    /* background-color: red; */
    /* background-color: green; */
}

.about-us-right .about-us-lower {
    position: absolute;
    left :51%;
}
.about-us-right .about-us-upper {
    position: absolute;
    left :16%;
}

@media (max-width: 768px) {
    .about-us {
        flex-direction: column; /* 在移动端上调整为垂直布局 */
        align-items: center; /* 在移动端上居中对齐 */
    }

    .about-us-left,
    .about-us-right {
        width: 100%; /* 在移动端上撑满整个宽度 */
        flex: none; /* 不再使用 flex 布局 */
    }
}