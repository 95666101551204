/* .info-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    flex: 1 1 auto;
    gap: 20px; 
    grid-template-columns: repeat(4, 1fr);
} */

/* .info-card-container{
    overflow: hidden;
    position: relative;
    /* flex:1; */
/* }  */

@media (max-width: 768px) {
    .info-card-container {
        grid-template-columns: 1fr;
    }
}