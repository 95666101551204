.banner {
    height: 300px;
    max-width: 100%;
    background-color: white;
    opacity: 0.95;
    /* border-top: 1px solid rgb(251, 239, 218);
    border-bottom: 1px solid rgb(251, 239, 218); */
    padding:65px;
    display: flex;
    position: relative;
    /* justify-content: space-between; */
    align-items: center;
    overflow: hidden;
    flex-direction: row;
    /* font-family: inherit; */
    /* font-family: sans-serif; */
    /* font-family: 'Space Grotesk'; */
    /* background: radial-gradient(circle at center, #FE5D27 18%, #0000 70%); */
    /* FFA500gin:40px; */ 
}



/* @media (max-width: 768px) {
    .banner {
        height: auto;
        flex-direction: column; 
        align-items: center; 
    }
    .banner-image {
        display: None;
    }
} */